import { createElement, defineModule, wrapElement } from '../utils/helpers';

const getElements = () => ({
  frmFormElements: document.querySelectorAll<HTMLElement>('.frm_forms'),
  formFieldElements: document.querySelectorAll<HTMLElement>('.frm_form_field'),
});

const wrapFormFieldControlElements = () => {
  const { formFieldElements } = getElements();

  formFieldElements.forEach((formFieldElement) => {
    const formFieldControlElement = formFieldElement.querySelector<HTMLElement>(
      '& > [name^="item_meta"]',
    );
    if (!formFieldControlElement) return;

    wrapElement(
      formFieldControlElement,
      createElement('div', {
        className: 'frm_field_wrapper',
      }),
    );
  });
};

const observer = new MutationObserver((mutations) => {
  mutations.forEach((mutation) => {
    if (
      mutation.type !== 'childList' ||
      !(mutation.target instanceof HTMLElement)
    ) {
      return;
    }

    if (mutation.target.querySelector('.frm_forms')) {
      const successMessageElement =
        mutation.target.querySelector<HTMLElement>('.frm_message');
      if (!successMessageElement) return;

      const modalElement = mutation.target.closest<HTMLElement>('.modal');
      if (modalElement) {
        modalElement.classList.add('modal--form-completed');
      }

      const submitWrapperElement =
        mutation.target.querySelector<HTMLElement>('.frm_submit');
      if (!submitWrapperElement) return;

      submitWrapperElement.appendChild(successMessageElement);
    }

    if (mutation.target.classList.contains('frm_form_field')) {
      let formFieldWrapperElement =
        mutation.target.querySelector<HTMLElement>('.frm_field_wrapper');
      if (!formFieldWrapperElement) {
        wrapFormFieldControlElements();
      }

      formFieldWrapperElement =
        mutation.target.querySelector<HTMLElement>('.frm_field_wrapper');
      if (!formFieldWrapperElement) return;

      const hasError = mutation.target.querySelector<HTMLElement>('.frm_error');
      formFieldWrapperElement.classList.toggle(
        'frm_field_wrapper--error',
        !!hasError,
      );

      const successMessageElement = mutation.target
        .closest<HTMLElement>('.frm_forms')
        ?.querySelector<HTMLElement>('.frm_submit .frm_message');
      if (successMessageElement) {
        successMessageElement.classList.add('!hidden');
      }
    }
  });
});

export default defineModule(
  () => {
    const { frmFormElements } = getElements();

    frmFormElements.forEach((frmFormElement) => {
      if (!frmFormElement.parentElement) return;

      observer.observe(frmFormElement.parentElement, {
        childList: true,
        subtree: true,
      });
    });

    wrapFormFieldControlElements();
  },
  () => {
    observer.disconnect();
  },
);
