import wretch from 'wretch';
import FormDataAddon from 'wretch/addons/formData';
import QueryStringAddon from 'wretch/addons/queryString';

export const AJAX_API = wretch(
  `${window.location.origin}/wp/wp-admin/admin-ajax.php`,
).addon(FormDataAddon);

export const POSTCODE_API = wretch('https://postcode.tech/api/v1/postcode')
  .addon(QueryStringAddon)
  .headers({
    Authorization: `Bearer ${import.meta.env.VITE_POSTCODE_API_KEY}`,
  });
