import { debounce } from 'lodash-es';
import { POSTCODE_API } from '../utils/api';
import { defineModule } from '../utils/helpers';

const getElements = () => ({
  formElements: document.querySelectorAll<HTMLElement>('.frm_forms'),
});

const zipcodeLookup = async (e: Event) => {
  if (!(e.target instanceof HTMLElement)) return;

  const formElement = e.target.closest<HTMLFormElement>('form');
  if (!formElement) return;

  const zipcodeInputElement = formElement.querySelector<HTMLInputElement>(
    '.zipcode__zipcode input',
  );
  const houseNumberInputElement = formElement.querySelector<HTMLInputElement>(
    '.zipcode__house-number input',
  );
  const addressInputElement = formElement.querySelector<HTMLInputElement>(
    '.zipcode__address input',
  );
  const cityInputElement = formElement.querySelector<HTMLInputElement>(
    '.zipcode__city input',
  );

  if (
    !zipcodeInputElement ||
    !houseNumberInputElement ||
    !addressInputElement ||
    !cityInputElement ||
    !zipcodeInputElement.value.length ||
    !houseNumberInputElement.value.length
  ) {
    return;
  }

  try {
    const { city, street } = await POSTCODE_API.query({
      postcode: zipcodeInputElement.value,
      number: houseNumberInputElement.value.replace(/\D+/g, ''),
    })
      .get()
      .json<{ city: string; street: string }>();

    addressInputElement.value = `${street} ${houseNumberInputElement.value}`;
    cityInputElement.value = city;
    addressInputElement.readOnly = true;
    cityInputElement.readOnly = true;
  } catch (error) {
    addressInputElement.value = '';
    cityInputElement.value = '';
    addressInputElement.readOnly = false;
    cityInputElement.readOnly = false;
  }
};

const debouncedZipcodeLookup = debounce((e: Event) => zipcodeLookup(e), 300);

export default defineModule(
  () => {
    const { formElements } = getElements();

    formElements.forEach((formElement) => {
      const zipcodeInputElement = formElement.querySelector<HTMLInputElement>(
        '.zipcode__zipcode input',
      );
      const houseNumberInputElement =
        formElement.querySelector<HTMLInputElement>(
          '.zipcode__house-number input',
        );
      const addressInputElement = formElement.querySelector<HTMLInputElement>(
        '.zipcode__address input',
      );
      const cityInputElement = formElement.querySelector<HTMLInputElement>(
        '.zipcode__city input',
      );

      if (
        !zipcodeInputElement ||
        !houseNumberInputElement ||
        !addressInputElement ||
        !cityInputElement
      ) {
        return;
      }

      [zipcodeInputElement, houseNumberInputElement].forEach((inputElement) =>
        inputElement.addEventListener('input', debouncedZipcodeLookup),
      );
    });
  },
  () => {
    const { formElements } = getElements();

    formElements.forEach((formElement) => {
      const zipcodeInputElement = formElement.querySelector<HTMLInputElement>(
        '.zipcode__zipcode input',
      );
      const houseNumberInputElement =
        formElement.querySelector<HTMLInputElement>(
          '.zipcode__house-number input',
        );
      const addressInputElement = formElement.querySelector<HTMLInputElement>(
        '.zipcode__address input',
      );
      const cityInputElement = formElement.querySelector<HTMLInputElement>(
        '.zipcode__city input',
      );

      if (
        !zipcodeInputElement ||
        !houseNumberInputElement ||
        !addressInputElement ||
        !cityInputElement
      ) {
        return;
      }

      [zipcodeInputElement, houseNumberInputElement].forEach((inputElement) =>
        inputElement.removeEventListener('input', debouncedZipcodeLookup),
      );
    });
  },
);
