import { debounce } from 'lodash-es';
import { createElement, defineModule, nextTick } from '../utils/helpers';

const getElements = () => ({
  buttonElements: document.querySelectorAll<HTMLElement>(
    ':is(.frm_submit button, .btn, .btn--ripple):not(.btn--no-ripple)',
  ),
});

const onButtonTouchStart = (e: TouchEvent) => {
  if (!(e.currentTarget instanceof HTMLElement)) return;

  const rippleContainerElement =
    e.currentTarget.querySelector<HTMLElement>('.ripple__container');
  if (!rippleContainerElement) return;

  const { clientWidth, clientHeight } = e.currentTarget;
  const { left, top } = e.currentTarget.getBoundingClientRect();
  const { clientX, clientY } = e.touches[e.touches.length - 1];

  const localX = clientX - left;
  const localY = clientY - top;

  const radius =
    clientWidth / 2 +
    Math.sqrt(
      (localX - clientWidth / 2) ** 2 + (localY - clientWidth / 2) ** 2,
    ) /
      4;

  const rippleElement = createElement('span', {
    className:
      'ripple__item ripple__item--in rounded-full absolute opacity-25 bg-current aspect-square',
  });

  rippleElement.style.setProperty('--x', `${localX - radius}px`);
  rippleElement.style.setProperty('--y', `${localY - radius}px`);
  Object.assign(rippleElement.style, { width: `${radius * 2}px` });

  nextTick(() => {
    rippleElement.style.setProperty('--scale', '1');
    rippleElement.style.setProperty(
      '--x',
      `${(clientWidth - radius * 2) / 2}px`,
    );
    rippleElement.style.setProperty(
      '--y',
      `${(clientHeight - radius * 2) / 2}px`,
    );
  });

  setTimeout(() => {
    rippleElement.classList.remove('ripple__item--in');
    rippleElement.classList.add('ripple__item--out', '!opacity-0');
  }, 250);

  rippleContainerElement.appendChild(rippleElement);
};

const onButtonTouchEnd = debounce((e: Event) => {
  if (!(e.target instanceof HTMLElement)) return;

  const rippleContainerElement =
    e.target.querySelector<HTMLElement>('.ripple__container') ??
    e.target
      .closest<HTMLElement>('.btn')
      ?.querySelector<HTMLElement>('.ripple__container') ??
    e.target
      .closest<HTMLElement>('.btn--ripple')
      ?.querySelector<HTMLElement>('.ripple__container');
  if (!rippleContainerElement) return;

  rippleContainerElement.replaceChildren();
}, 2000);

export default defineModule(
  () => {
    const { buttonElements } = getElements();

    buttonElements.forEach((buttonElement) => {
      buttonElement.appendChild(
        createElement('div', {
          className:
            'ripple__container rounded-[inherit] absolute inset-0 overflow-hidden w-full h-full pointer-events-none',
        }),
      );

      buttonElement.addEventListener('touchstart', onButtonTouchStart, {
        passive: true,
      });
      buttonElement.addEventListener('touchend', onButtonTouchEnd, {
        passive: true,
      });
      buttonElement.addEventListener('touchcancel', onButtonTouchEnd, {
        passive: true,
      });
    });
  },
  () => {
    const { buttonElements } = getElements();

    buttonElements.forEach((buttonElement) => {
      buttonElement.removeEventListener('touchstart', onButtonTouchStart);
      buttonElement.removeEventListener('touchend', onButtonTouchEnd);
      buttonElement.removeEventListener('touchcancel', onButtonTouchEnd);
    });
  },
);
