import { createFocusTrap, type FocusTrap } from 'focus-trap';
import { defineModule } from '../../utils/helpers';
import { Overlay, toggleOverlay } from '../../utils/overlays';

let focusTrap: FocusTrap | null = null;

export type ModalId = 'newsletter' | 'video';

const getElements = () => ({
  openModalElements:
    document.querySelectorAll<HTMLElement>('[data-open-modal]'),
  dismissModalElements: document.querySelectorAll<HTMLElement>(
    '[data-dismiss-modal]',
  ),
  toggleModalElements: document.querySelectorAll<HTMLElement>(
    '[data-toggle-modal]',
  ),
});

export const toggleModal = (id: ModalId, force?: boolean) => {
  const modalElement = document.querySelector<HTMLElement>(
    `[data-modal="${id}"]`,
  );
  if (!modalElement) return;

  const modalContentElement =
    modalElement.querySelector<HTMLElement>('.modal__content');
  if (!modalContentElement) return;

  force = force ?? !modalElement.classList.contains('modal--open');

  toggleOverlay(Overlay.MODAL, force);
  modalElement.classList.toggle('modal--open', force);

  if (force) {
    modalElement.setAttribute('aria-modal', 'true');
    modalElement.removeAttribute('aria-hidden');

    focusTrap = createFocusTrap(modalElement, {
      initialFocus: modalElement,
      allowOutsideClick: true,
      escapeDeactivates: false,
    });

    modalContentElement.addEventListener(
      'transitionend',
      () => focusTrap?.activate(),
      { once: true, passive: true },
    );
  } else {
    modalElement.removeAttribute('aria-modal');
    modalElement.setAttribute('aria-hidden', 'true');

    focusTrap?.deactivate();
  }
};

const handleOutsideModalContentClick = (e: Event) => {
  if (!(e.target instanceof HTMLElement)) return;

  const isOutsideClick = !e.target.closest<HTMLElement>('.modal__content');
  if (!isOutsideClick) return;

  const modalElement = e.target.closest<HTMLElement>('.modal');
  if (!modalElement) return;

  const modalId = modalElement.getAttribute('data-modal');
  if (!modalId) return;

  document.dispatchEvent(
    new CustomEvent(`dismiss-modal:${modalId}`, {
      detail: { triggerElement: null, modalElement },
    }),
  );

  toggleModal(modalId as ModalId, false);
};

const handleModalEvent = (e: Event) => {
  if (!(e.currentTarget instanceof HTMLElement)) return;

  const [shouldOpen, shouldClose] = [
    'data-open-modal',
    'data-dismiss-modal',
  ].map((attr) => (e.currentTarget as HTMLElement).getAttribute(attr));

  const targetModal = shouldOpen || shouldClose;
  if (!targetModal) return;

  const modalElement = document.querySelector<HTMLElement>(
    `[data-modal="${targetModal}"]`,
  );
  if (!modalElement) return;

  /* eslint-disable */
  const force = shouldOpen
    ? true
    : shouldClose
      ? false
      : !modalElement.classList.contains('modal--open');
  /* eslint-enable */

  document.dispatchEvent(
    new CustomEvent(
      `${force ? 'open-modal' : 'dismiss-modal'}:${targetModal}`,
      { detail: { triggerElement: e.currentTarget, modalElement } },
    ),
  );

  toggleModal(targetModal as ModalId, force);
};

export default defineModule(
  () => {
    const { openModalElements, dismissModalElements, toggleModalElements } =
      getElements();

    [
      ...openModalElements,
      ...dismissModalElements,
      ...toggleModalElements,
    ].forEach((openModalElement) =>
      openModalElement.addEventListener('click', handleModalEvent, {
        passive: true,
      }),
    );

    document.addEventListener('click', handleOutsideModalContentClick, {
      passive: true,
    });
  },
  () => {
    const { openModalElements, dismissModalElements, toggleModalElements } =
      getElements();

    [
      ...openModalElements,
      ...dismissModalElements,
      ...toggleModalElements,
    ].forEach((openModalElement) =>
      openModalElement.removeEventListener('click', handleModalEvent),
    );

    focusTrap?.deactivate();

    document.removeEventListener('click', handleOutsideModalContentClick);
  },
);
