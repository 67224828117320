import type { Splide } from '@splidejs/splide';
import { defineModule } from '../utils/helpers';

const splides: Splide[] = [];

const getElements = () => ({
  reviewsSplideElements: document.querySelectorAll<HTMLElement>(
    '.content-block--reviews .splide',
  ),
});

export default defineModule(
  async () => {
    const { reviewsSplideElements } = getElements();
    if (!reviewsSplideElements.length) return;

    const { Splide } = await import('@splidejs/splide');

    reviewsSplideElements.forEach((reviewsSplideElement) => {
      const splide = new Splide(reviewsSplideElement, {
        type: 'loop',
        perPage: 1,
        autoplay: true,
        interval: 6000,
      });

      splide.on('autoplay:playing', (rate) => {
        splide.Components.Elements.pagination?.style.setProperty(
          '--progress',
          `${rate}`,
        );
      });

      splides.push(splide.mount());
    });
  },
  () => {
    while (splides.length > 0) {
      splides.pop()?.destroy(true);
    }
  },
);
