import type Plyr from 'plyr';
import { createElement, defineModule } from '../../utils/helpers';
import { players, createPlayer } from '../../blocks/video';
import type { CustomEventMap } from '../../global';

let player: Plyr | null = null;

const onVideoModalOpen = ({
  detail: { modalElement, triggerElement },
}: CustomEventMap['open-modal:video']): void => {
  if (!triggerElement) return;

  const originalPlayerElement = triggerElement
    .closest<HTMLElement>('.content-block--video')
    ?.querySelector<HTMLElement>('.plyr');
  const originalPlayer = players.find(
    (_player) => _player.elements.container === originalPlayerElement,
  );
  if (!originalPlayer) return;

  const videoWrapperElement =
    modalElement.querySelector<HTMLElement>('.video__wrapper');
  if (!videoWrapperElement) return;

  const plyrElement = createElement('div');
  plyrElement.setAttribute('data-plyr-provider', originalPlayer.provider);
  plyrElement.setAttribute(
    'data-plyr-embed-id',
    originalPlayer.source as unknown as string,
  );
  videoWrapperElement.replaceChildren();
  videoWrapperElement.appendChild(plyrElement);

  player = createPlayer(plyrElement);

  player.on('ready', () => {
    if (!player) return;

    player.play();
  });
};

const onVideoModalDismiss = ({
  detail: { modalElement },
}: CustomEventMap['dismiss-modal:video']): void => {
  if (!player) return;

  player.stop();
  player.destroy();
};

export default defineModule(
  () => {
    document.addEventListener('open-modal:video', onVideoModalOpen);
    document.addEventListener('dismiss-modal:video', onVideoModalDismiss);
  },
  () => {
    document.removeEventListener('open-modal:video', onVideoModalOpen);
    document.removeEventListener('dismiss-modal:video', onVideoModalDismiss);
  },
);
