import { defineModule, isFunction } from '../utils/helpers';

declare const cmplz_set_banner_status: unknown;
declare const cmplz_set_cookie: unknown;

const getElements = () => ({
  quickMenuElements:
    document.querySelectorAll<HTMLElement>('[data-quick-menu]'),
});

const onQuickMenuElementClick = (e: Event) => {
  if (!(e.currentTarget instanceof HTMLElement)) return;

  const action = e.currentTarget.getAttribute('data-quick-menu');
  if (!action) return;

  try {
    switch (action) {
      case 'cookie': {
        if (
          isFunction(cmplz_set_banner_status) &&
          isFunction(cmplz_set_cookie)
        ) {
          cmplz_set_banner_status('show');
          cmplz_set_cookie('banner-status', 'dismissed');
        }
        break;
      }
      case 'chat': {
        // TODO: Chat popup
        break;
      }
      default:
        break;
    }
  } catch (error) {
    // Do nothing...
  }
};

export default defineModule(
  () => {
    const { quickMenuElements } = getElements();

    quickMenuElements.forEach((quickMenuElement) => {
      quickMenuElement.addEventListener('click', onQuickMenuElementClick);
    });
  },
  () => {
    const { quickMenuElements } = getElements();

    quickMenuElements.forEach((quickMenuElement) => {
      quickMenuElement.removeEventListener('click', onQuickMenuElementClick);
    });
  },
);
