import { defineModule } from '../utils/helpers';

const getElements = () => ({
  accordionElements: document.querySelectorAll<HTMLElement>(
    '.content-block--faq .accordion',
  ),
  showMoreElements: document.querySelectorAll<HTMLElement>(
    '.content-block--faq .acccordion-list__show-more',
  ),
});

const onAccordionTriggerClick = (e: Event) => {
  if (!(e.currentTarget instanceof HTMLElement)) return;

  const faqSectionListElement =
    e.currentTarget.closest<HTMLElement>('.section__list');
  if (!faqSectionListElement) return;

  const accordionElement = e.currentTarget.closest<HTMLElement>('.accordion');
  if (!accordionElement) return;

  const accordionContentElement = accordionElement.querySelector<HTMLElement>(
    '.accordion__content',
  );
  if (!accordionContentElement) return;

  const isOpen = e.currentTarget.getAttribute('aria-expanded') === 'true';

  const accordionElements =
    faqSectionListElement.querySelectorAll<HTMLElement>('.accordion');
  accordionElements.forEach((_accordionElement) => {
    const isCurrentAccordionElement = _accordionElement === accordionElement;
    const shouldOpen = isCurrentAccordionElement && !isOpen;

    _accordionElement.classList.toggle('accordion--open', shouldOpen);

    const _accordionTriggerElement =
      _accordionElement.querySelector<HTMLElement>('.accordion__trigger');
    if (!_accordionTriggerElement) return;

    const _accordionContentElement =
      _accordionElement.querySelector<HTMLElement>('.accordion__content');
    if (!_accordionContentElement) return;

    _accordionTriggerElement.ariaExpanded = `${shouldOpen}`;
    _accordionContentElement.ariaHidden = `${!shouldOpen}`;
  });
};

const onShowMoreAccordionElementsClick = (e: Event) => {
  if (!(e.currentTarget instanceof HTMLElement)) return;

  const faqSectionElement =
    e.currentTarget.closest<HTMLElement>('.faq__section');
  if (!faqSectionElement) return;

  const hiddenAccordionElements = [
    ...faqSectionElement.querySelectorAll<HTMLElement>('.accordion'),
  ].filter(
    (accordionItemElement) =>
      window.getComputedStyle(accordionItemElement).display === 'none',
  );
  hiddenAccordionElements.slice(0, 3).forEach((hiddenAccordionElement) => {
    hiddenAccordionElement.classList.add('!block');
  });

  if (hiddenAccordionElements.length <= 3) {
    e.currentTarget.classList.add('hidden');
  }
};

export default defineModule(
  () => {
    const { accordionElements, showMoreElements } = getElements();

    accordionElements.forEach((accordionElement) => {
      const accordionTriggerElement = accordionElement.querySelector(
        '.accordion__trigger',
      );
      if (!accordionTriggerElement) return;

      accordionTriggerElement.addEventListener(
        'click',
        onAccordionTriggerClick,
      );
    });

    showMoreElements.forEach((showMoreElement) => {
      showMoreElement.addEventListener(
        'click',
        onShowMoreAccordionElementsClick,
      );
    });
  },
  () => {
    const { accordionElements, showMoreElements } = getElements();

    accordionElements.forEach((accordionElement) => {
      const accordionTriggerElement = accordionElement.querySelector(
        '.accordion__trigger',
      );
      if (!accordionTriggerElement) return;

      accordionTriggerElement.removeEventListener(
        'click',
        onAccordionTriggerClick,
      );
    });

    showMoreElements.forEach((showMoreElement) => {
      showMoreElement.removeEventListener(
        'click',
        onShowMoreAccordionElementsClick,
      );
    });
  },
);
