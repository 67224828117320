import { BREAKPOINT_MEDIA_QUERIES } from '../utils/breakpoints';
import { defineModule, nextTick } from '../utils/helpers';

let subMenuTimeout: NodeJS.Timeout;

const getElements = () => ({
  navbar: document.querySelector<HTMLElement>('nav.navbar'),
  navButtons: document.querySelectorAll<HTMLButtonElement>(
    'nav.navbar > div > button[aria-controls]',
  ),
  navSubButtons: document.querySelectorAll<HTMLButtonElement>(
    'nav.navbar button[aria-controls^="sub-menu"]',
  ),
  navSubItems: document.querySelectorAll<HTMLElement>(
    'nav.navbar .navbar__menu > .menu-item-has-children',
  ),
});

const toggleNavTransitions = (force: boolean) => {
  const { navbar } = getElements();
  if (!navbar) return;

  navbar.classList.toggle('navbar--no-transitions', force);
};

let closingTimeout: NodeJS.Timeout;
const toggleNavButton = (
  e: Event,
  force?: boolean,
  button?: HTMLButtonElement,
) => {
  const { navbar } = getElements();
  if (!navbar) return;

  const btn = button ?? (e.currentTarget as HTMLButtonElement);
  const expand = force ?? btn.ariaExpanded === 'false';
  const closing = !expand && btn.ariaExpanded === 'true';
  const atTop = (window.scrollY || window.pageYOffset) === 0;

  clearTimeout(closingTimeout);
  if (closing && !atTop) {
    navbar.classList.add('navbar--closing');
    closingTimeout = setTimeout(() => {
      navbar.classList.remove('navbar--closing');
    }, 500);
  }

  btn.ariaExpanded = String(expand);

  const { navSubButtons } = getElements();
  navSubButtons.forEach((sb) => {
    sb.ariaExpanded = String(false);
  });
};

const closeMenu = (e: Event) => {
  const { navbar, navButtons } = getElements();
  if (!navbar) return;

  if (e.type === 'mouseleave' && !BREAKPOINT_MEDIA_QUERIES.lg.matches) return;

  toggleNavButton(e, false, navButtons.item(0));
};

const toggleSubMenu = (menuLi: HTMLLIElement, expand: boolean) => {
  const subMenu = menuLi.querySelector<HTMLDivElement>('& > .sub-menu');
  const subId = subMenu?.id;

  if (!subId) return;

  const { navSubButtons } = getElements();

  const openSubmenu = Array.from(navSubButtons).some(
    (btn) =>
      btn.ariaExpanded === 'true' &&
      btn.getAttribute('aria-controls') !== subId,
  );
  toggleNavTransitions(openSubmenu);

  navSubButtons.forEach((btn) => {
    const expandButton = String(
      btn.getAttribute('aria-controls') === subId && expand,
    );
    btn.ariaExpanded = expandButton;
  });

  if (!openSubmenu) return;

  nextTick(() => {
    toggleNavTransitions(false);
  });
};

const clickNavSubButton = (e: Event) => {
  const btn = e.currentTarget as HTMLButtonElement;
  const expand = !(btn.ariaExpanded === 'true');
  const menuLi = btn.closest<HTMLLIElement>('.menu-item-has-children');

  if (!menuLi) return;

  toggleSubMenu(menuLi, expand);
};

const hoverSubMenu = (e: PointerEvent) => {
  if (!BREAKPOINT_MEDIA_QUERIES.lg.matches) return;

  const menuLi = e.currentTarget as HTMLLIElement;
  if (
    !menuLi ||
    !['pointerenter', 'pointerleave'].includes(e.type) ||
    e.pointerType !== 'mouse'
  )
    return;

  const expand = e.type === 'pointerenter';

  clearTimeout(subMenuTimeout);

  if (expand) {
    toggleSubMenu(menuLi, expand);
    return;
  }

  subMenuTimeout = setTimeout(() => {
    toggleSubMenu(menuLi, expand);
  }, 250);
};

const navbarBreakpointChecker = (e: Event) => {
  const { navButtons } = getElements();
  if (!navButtons) return;

  toggleNavTransitions(true);

  closeMenu(e);

  nextTick(() => {
    toggleNavTransitions(false);
  });
};

export default defineModule(
  () => {
    const { navbar, navButtons, navSubButtons, navSubItems } = getElements();
    if (!navbar) return;

    navButtons.forEach((btn) =>
      btn.addEventListener('click', toggleNavButton, { passive: true }),
    );
    navSubButtons.forEach((btn) => {
      btn.addEventListener('click', clickNavSubButton, { passive: true });
    });
    navSubItems.forEach((li) => {
      li.addEventListener('pointerenter', hoverSubMenu, { passive: true });
    });
    navbar.addEventListener('mouseleave', closeMenu, { passive: true });

    BREAKPOINT_MEDIA_QUERIES.lg.addEventListener(
      'change',
      navbarBreakpointChecker,
      { passive: true },
    );
  },
  () => {
    const { navbar, navButtons, navSubButtons, navSubItems } = getElements();
    if (!navbar) return;

    navButtons.forEach((btn) =>
      btn.removeEventListener('click', toggleNavButton),
    );
    navSubButtons.forEach((btn) =>
      btn.removeEventListener('click', clickNavSubButton),
    );
    navSubItems.forEach((li) => {
      li.removeEventListener('pointerenter', hoverSubMenu);
    });
    navbar.addEventListener('pointerleave', closeMenu);

    BREAKPOINT_MEDIA_QUERIES.lg.removeEventListener(
      'change',
      navbarBreakpointChecker,
    );
  },
);
