import Plyr from 'plyr';
import { createElement, defineModule } from '../utils/helpers';
import { BREAKPOINT_MEDIA_QUERIES } from '../utils/breakpoints';

export const players: Plyr[] = [];

const getElements = () => ({
  plyrElements: document.querySelectorAll<HTMLElement>(
    '.content-block--video [data-plyr]',
  ),
});

const onBreakpointMediaChange = (e: MediaQueryListEvent) => {
  if (e.matches) return;

  players.forEach((player) => {
    if (player.elements.container?.closest<HTMLElement>('.modal')) return;

    player.pause();
  });
};

const getVideoTitle = async (player: Plyr): Promise<string | undefined> =>
  player.embed?.getVideoTitle();

export const createPlayer = (plyrElement: HTMLElement) =>
  new Plyr(plyrElement, {
    fullscreen: {
      enabled: false,
    },
    vimeo: {
      controls: false,
    },
    i18n: {
      restart: 'Herstarten',
      rewind: 'Spoel {seektime}s terug',
      play: 'Afspelen',
      pause: 'Pauzeren',
      fastForward: 'Spoel {seektime}s vooruit',
      seek: 'Zoek',
      seekLabel: '{currentTime} van {duration}',
      played: 'Gespeeld',
      buffered: 'Gebufferd',
      currentTime: 'Huidige tijd',
      duration: 'Looptijd',
      volume: 'Volume',
      mute: 'Dempen',
      unmute: 'Dempen opheffen',
      enableCaptions: 'Schakel ondertiteling in',
      disableCaptions: 'Schakel ondertiteling uit',
      download: 'Download',
      enterFullscreen: 'Volledig scherm openen',
      exitFullscreen: 'Volledig scherm sluiten',
      frameTitle: 'Speler voor {title}',
      captions: 'Ondertiteling',
      settings: 'Instellingen',
      pip: 'PIP',
      menuBack: 'Ga terug naar het vorige menu',
      speed: 'Snelheid',
      normal: 'Normaal',
      quality: 'Kwaliteit',
      loop: 'Lus',
      start: 'Start',
      end: 'Einde',
      all: 'Alles',
      reset: 'Reset',
      disabled: 'Uitgeschakeld',
      enabled: 'Ingeschakeld',
      advertisement: 'Advertentie',
      qualityBadge: {
        2160: '4K',
        1440: 'HD',
        1080: 'HD',
        720: 'HD',
        576: 'SD',
        480: 'SD',
      },
    },
  });

export default defineModule(
  async () => {
    const { plyrElements } = getElements();
    if (!plyrElements.length) return;

    plyrElements.forEach((plyrElement) => {
      const player = createPlayer(plyrElement);

      player.on('ended', () =>
        player.elements.container?.classList.add('plyr--ended'),
      );

      player.on('play', () =>
        player.elements.container?.classList.remove('plyr--ended'),
      );

      player.on('ready', async () => {
        // @ts-ignore
        player.poster = player.elements.original.getAttribute(
          'data-plyr-thumbnail-url',
        );

        const title =
          (Boolean(
            // @ts-ignore
            player.elements.original.getAttribute('data-plyr-show-title'),
          ) &&
            // @ts-ignore
            player.elements.original.getAttribute('data-plyr-title')) ||
          (await getVideoTitle(player));

        if (title) {
          const mobileVideoTitleElement = player.elements.container
            ?.closest<HTMLElement>('.content-block--video')
            ?.querySelector<HTMLElement>('[data-video-title]');
          if (mobileVideoTitleElement) {
            mobileVideoTitleElement.innerText = title;
          }
        }

        player.elements.container?.prepend(
          createElement('div', { className: 'plyr__overlay' }, [
            createElement(
              'button',
              {
                className: 'btn btn--outlined-dark plyr__play',
                onclick: () => {
                  player.play();
                },
              },
              ['Afspelen'],
            ),
            title && createElement('h4', { className: 'plyr__title' }, [title]),
          ]),
        );

        player.elements.container?.classList.add('plyr--ready');
      });

      BREAKPOINT_MEDIA_QUERIES.lg.addEventListener(
        'change',
        onBreakpointMediaChange,
      );

      players.push(player);
    });
  },
  () => {
    while (players.length > 0) {
      const player = players.pop();
      player?.stop();
      player?.destroy();
    }
  },
);
