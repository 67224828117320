import type { Splide } from '@splidejs/splide';
import { defineModule } from '../utils/helpers';
import { BREAKPOINTS } from '../utils/breakpoints';

const splides: Splide[] = [];

const getElements = () => ({
  imagesSliderSplideElements: document.querySelectorAll<HTMLElement>(
    '.content-block--images-slider.splide',
  ),
});

export default defineModule(
  async () => {
    const { imagesSliderSplideElements } = getElements();
    if (!imagesSliderSplideElements.length) return;

    const { Splide } = await import('@splidejs/splide');

    imagesSliderSplideElements.forEach((imagesSliderSplideElement) => {
      splides.push(
        new Splide(imagesSliderSplideElement, {
          type: 'loop',
          perPage: 1,
          autoWidth: true,
          cloneStatus: false,
          focus: 'center',
          updateOnMove: true,
          gap: '.5rem',

          mediaQuery: 'min',
          breakpoints: {
            [BREAKPOINTS.lg]: {
              gap: '1rem',
            },
          },
        }).mount(),
      );
    });
  },
  () => {
    while (splides.length > 0) {
      splides.pop()?.destroy(true);
    }
  },
);
